body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: MuseoSlab700;
    src: url("./fonts/Museo_Slab_700.otf");
}

@font-face {
    font-family: MuseoSlab300;
    src: url("./fonts/Museo_Slab_300.otf");
}

@font-face {
    font-family: MuseoSans300;
    src: url("./fonts/Museo_Sans_300.otf");
}

@font-face {
    font-family: MuseoSans700;
    src: url("./fonts/Museo_Sans_700.otf");
}